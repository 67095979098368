import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "../components/LogoutButton";
import ToggleButton from "../components/ToggleButton";
import LoginButton from "../components/LoginButton";
import SignUpButton from "../components/SignUpButton"; 


function Account() {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <main>
            {isAuthenticated && user ? (
                <div>
                    <img src={user.picture} alt={user.name} className="profile-image" />
                    <p>{user.email}</p>
                    <div className="d-flex justify-content-center">
                        <ToggleButton />
                    </div>
                    <LogoutButton />
                </div>
            ) : (
                <div>
                    <h2>Please log in or sign up to view your account</h2>
                    <LoginButton />
                </div>
            )}
        </main>
    );
}

export default Account;
