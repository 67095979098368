// src/pages/IPNotice.js
import React from 'react';
import "../IPNotice.css"; 

function IPNotice() {
    return (
        <main>
            <h1>Intellectual Property Notice</h1>
            <p>
            Appointment Scanner by Investorlink LLC. 
            Appointment Scanner is a third-party service that helps users find interview appointments. 
            We do not process IDNYC applications and we cannot help users get approved for IDNYC. We just make it easy for users to know when appointment slots have opened up due to last-minute cancellations. 
            Appointment Scanner is not affiliated with or endorsed by any government agencies.
            The unstylized text, "IDNYC", is used under "nominative fair use" to identify that our service is an accessory service that works with IDNYC appointments. We do not use the IDNYC logo or any government agency logos in any of our webpages or promotions.
            All data on interview appointment availability is in the public domain, and is sourced from the public IDNYC website.
            </p>
        </main>
    );
}

export default IPNotice;