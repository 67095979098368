import { Button } from "bootstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoute";
import Account from "./pages/Account";
import Home from "./pages/Home";
import IPNotice from "./pages/IPNotice";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { Analytics } from "@vercel/analytics/react"

function App() {
    return (
        <div className="App">
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/ip-notice" element={<IPNotice />} />
                </Routes>
                <Footer />
            </Router>
            <Analytics />
        </div>
    );
}

export default App;

