import LoginButton from "../components/LoginButton";
import LogoutButton from "../components/LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";
import BuyNowButton from "../components/BuyNowButton";


function Home() {
    const { user, isAuthenticated, isLoading } = useAuth0();
    return (
        <>
            <div className="d-flex flex-column h-100">
                <div className="flex-shrink-0">
                    {/* <!-- Header--> */}
                    <header className="py-5">
                        <div className="container px-5 pb-5">
                            <div className="row gx-5 align-items-center">
                                <div className="col-md-6">
                                    {/* <!-- Header text content--> */}
                                    <div className="text-center text-md-start">
                                        <h1 className="display-3 fw-bolder mb-5">
                                            <span className="text-gradient d-inline">
                                                Get Notified Instantly When
                                                IDNYC Appointments Become
                                                Available
                                            </span>
                                        </h1>
                                        <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-md-start mb-3">
                                            <a
                                                className="btn btn-primary btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder"
                                                href="#how-it-works">
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {/* <!-- Header image or illustration--> */}
                                    <div className="d-flex justify-content-center justify-content-md-end">
                                        <img
                                            className="img-fluid rounded"
                                            src="email_notification.png"
                                            alt="IDNYC Notification Illustration"
                                            // style={{max-width : 100% , height: "auto" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    {/* <!-- How it Works Section--> */}
                    <section className="py-5" id="how-it-works">
                        <div className="container px-5">
                            <h2 className="fw-bolder mb-4">How it Works</h2>
                            <ul className="list-unstyled mb-0">
                                <li className="mb-3">
                                    ✉️ Sign up with your email address
                                </li>
                                <li className="mb-3">
                                    🔍 We continuously scan for available
                                    appointments
                                </li>
                                <li className="mb-3">
                                    🔔 Receive instant email notifications
                                </li>
                                <li className="mb-3">
                                    📅 Book your appointment quickly
                                </li>
                            </ul>
                        </div>
                    </section>

                    {/* <!-- Pricing Section--> */}
                    <section className="py-5 bg-light" id="pricing">
                        <div className="container px-5">
                            <h2 className="fw-bolder mb-4">Pricing</h2>
                            {/*<h3 className="fw-bolder">$9 one-time payment</h3>*/}
                            <h3 className="fw-bolder">$49/month</h3>
                            <p className="mb-4">
                                <strong>What’s included 👇</strong>
                            </p>
                            <ul className="list-unstyled mb-0">
                                <li className="mb-3">
                                    ✅ Unlimited appointment notifications
                                </li>
                                <li className="mb-3">
                                    ✅ Supports all appointment locations in all
                                    5 boroughs
                                </li>
                                <li className="mb-3">✅ Notifications to as many as 20 email addresses</li>
                            </ul>
                            <script
                                async
                                src="https://js.stripe.com/v3/buy-button.js"></script>

                            <div className="d-flex justify-content-center mt-4">
                                <BuyNowButton />
                            </div>
                        </div>
                    </section>

                    {/* <!-- FAQ Section--> */}
                    <section className="py-5" id="faq">
                        <div className="container px-5">
                            <h2 className="fw-bolder mb-4">FAQ</h2>
                            <div className="accordion" id="faqAccordion">
                                <div className="accordion-item">
                                    <h3
                                        className="accordion-header"
                                        id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne">
                                            🕒 How often do you check for
                                            appointments?
                                        </button>
                                    </h3>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#faqAccordion">
                                        <div className="accordion-body">
                                            We scan for new IDNYC appointments
                                            continuously throughout the day,
                                            ensuring you never miss an
                                            opportunity.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h3
                                        className="accordion-header"
                                        id="headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            ⚡ How quickly will I be notified?
                                        </button>
                                    </h3>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#faqAccordion">
                                        <div className="accordion-body">
                                            You will receive an email
                                            notification within minutes of an
                                            appointment becoming available.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h3
                                        className="accordion-header"
                                        id="headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree">
                                            🎯 Do you guarantee I'll get an
                                            appointment?
                                        </button>
                                    </h3>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#faqAccordion">
                                        <div className="accordion-body">
                                            While we can't guarantee an
                                            appointment, our service
                                            significantly increases your chances
                                            by providing instant notifications.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h3
                                        className="accordion-header"
                                        id="headingFour">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour">
                                            📍 Can I specify which IDNYC center
                                            I want?
                                        </button>
                                    </h3>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#faqAccordion">
                                        <div className="accordion-body">
                                            Currently, our system is set up to
                                            provide notifications for every
                                            location.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h3
                                        className="accordion-header"
                                        id="headingFive">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive">
                                            📱 Do you offer text message
                                            notifications?
                                        </button>
                                    </h3>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#faqAccordion">
                                        <div className="accordion-body">
                                            Currently, we only offer email
                                            notifications to ensure reliable
                                            delivery of appointment alerts.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                {/* <!-- Bootstrap core JS--> */}
                {/* <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script> */}
                {/* <!-- Core theme JS--> */}
                {/* <script src="js/scripts.js"></script> */}
            </div>
            {/* </Router> */}
        </>
    );
}
export default Home;
