import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

function LogoutButton() {
    const { logout } = useAuth0();

    return (
        <button className="btn btn-dark m-4"
            onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
            }>
            Log Out
        </button>
    );
}

export default LogoutButton;
