import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

function ToggleButton({ initialChecked }) {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [isChecked, setIsChecked] = useState(initialChecked);

    useEffect(() => {
        setIsChecked(initialChecked);
    }, [initialChecked]);

    const handleChange = async (event) => {
        setIsChecked(event.target.checked);
        try {
            const resp = await fetch(
                `${process.env.REACT_APP_API_URL}/user/${user.email}`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        receive_email_notifications: event.target.checked
                    })
                }
            );
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="">
            <div className="form-check form-switch d-flex">
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={isChecked}
                    onChange={handleChange}
                />

                <h4 className="form-check-label ms-3">
                    Receive email notifications
                </h4>
            </div>
        </div>
    );
}

export default ToggleButton;