import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

function LoginButton() {
    const { loginWithRedirect } = useAuth0();

    return (
        <button className="btn btn-outline-primary m-5" onClick={() => loginWithRedirect(
            {
                redirect_uri: `${window.location.origin}/account`
            }

        )}>
            Log In/Sign up
        </button>
    );
}

export default LoginButton;
