function Footer() {
    return (
        <footer className="bg-light py-4">
            <div className="container px-5">
                <div className="small text-center text-muted">
                    © 2024 IDNYC Appointment Scanner. All rights reserved.
                    Privately-owned service, not affiliated with or endorsed by
                    any government agencies.{" "}
                    <a className="text-primary small" href="/ip-notice">
                        More Info
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
