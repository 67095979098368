import React from 'react';

const BuyNowButton = () => {
    return (
        <a href="https://buy.stripe.com/5kAg0zaKQgCGh0IeUU" target="_blank" rel="noopener noreferrer">
            <button className="btn btn-buy-now">
                Buy Now
            </button>
        </a>
    );
};

export default BuyNowButton;